<template>
  <validation-observer ref="formCustomers">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              sm="3"
              class="mb-2"
            >
              <div class="image-input">
                <validation-provider
                  #default="{ errors }"
                  name="Photo"
                  :rules="vars.rulesImage"
                >
                  <b-img
                    :src="getUrlOfFile(vars.photo)"
                    class="mb-2"
                  />
                  <label
                    v-if="!isDetailPage"
                    for="file-input"
                  >
                    <input
                      id="file-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      hidden
                      @change="AddPhoto"
                    >
                    <feather-icon icon="PlusIcon" />
                    Upload Photo
                  </label>
                  <input
                    v-model="vars.photo"
                    type="hidden"
                    :state="errors.length > 0 ? false:null"
                  >
                  <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Member ID</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input
                    id="Name"
                    v-model="vars.memberId"
                    type="text"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="email"
                  >Email</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="models.email"
                      :disabled="isDetailPage"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="phone_number"
                  >No Telepon</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="No. telepon"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="models.phone"
                      :disabled="isDetailPage"
                      type="tel"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="bod"
                  >Tanggal Lahir</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal lahir"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="bod"
                      v-model="models.bod"
                      show-decade-nav
                      :max="vars.maxDate"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Jenis Kelamin</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    v-if="isDetailPage"
                    :value="models.gender.toUpperCase()"
                    disabled
                  />
                  <div
                    v-else
                    class="col-sm-6 d-flex justify-content-between"
                  >
                    <label class="radio-inline">
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="perempuan"
                        name="gender"
                      >
                      <span>Perempuan</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.gender"
                        type="radio"
                        value="laki"
                        name="gender"
                      >
                      <span>Laki-Laki</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="address"
                  >Alamat</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required"
                  >
                    <b-form-textarea
                      id="address"
                      v-model="models.address"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="kfs"
                  >KFS</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="KFS"
                    rules="required"
                  >
                    <b-form-select
                      id="kfs"
                      v-model="models.kfsId"
                      :disabled="isKFSCustomerForm"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.kfs"
                      value-field="id"
                      text-field="name"
                      class="w-100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="tag"
                  >Tags</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Tag"
                    rules="required"
                  >
                    <v-select
                      id="tag"
                      v-model="models.since"
                      label="name"
                      placeholder="- Cari Tag -"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.regencies"
                      :reduce="option => option"
                      :disabled="isDetailPage"
                      @search="debounceSearch"
                      @input="setTagInfo"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Tidak ditemukan hasil pencarian
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="note"
                  >Catatan</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Catatan"
                    rules="required"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="models.note"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">

            <b-button
              v-if="isEditCustomerPage"
              type="button"
              :variant="`outline-${statusCustomer ? 'danger' : 'success'}`"
              class="btn-min-width rounded"
              @click="updateStatus"
            >
              {{ statusCustomer ? 'Non-Aktif' : 'Aktif' }}
            </b-button>
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded mx-md-1 my-1 my-md-0"
              @click="$router.push({path: goBackPath})"
            >
              Kembali
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'

import {
  BCard, BRow, BCol, BFormSelect, BButton, BImg, BFormInput, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, numeric, email, image,
} from '@validations'
import api from '@/utils/api'

// eslint-disable-next-line import/no-cycle
import { getUrlOfFile, uploadFile } from '@/utils/helpers'

export default {
  name: 'FormCustomers',
  metaInfo: {
    title: 'Form Customers',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        kfsId: '',
        name: '',
        photo: '',
        gender: 'perempuan',
        bod: '',
        phone: '',
        email: '',
        address: '',
        since: '',
        note: '',
      },
      vars: {
        rulesImage: 'required|image',
        photo: null,
        maxDate: new Date(),
        memberId: '',
        status: null,
      },
      masters: {
        regencies: [],
        kfs: [
          {
            id: '',
            name: '- Pilih KFS -',
          },
        ],
      },
      // Functions
      getUrlOfFile,

      required,
      numeric,
      email,
      image,
    }
  },
  computed: {
    isEditCustomerPage() {
      return this.$route.name === 'edit-customer' || this.$route.name === 'detail-kfs-customer-edit'
    },
    isDetailPage() {
      return this.$route.name === 'detail-customer' || this.$route.name === 'detail-kfs-customer'
    },
    imageField() {
      return this.vars.photo
    },
    statusCustomer() {
      return this.vars.status
    },
    isKFSCustomerForm() {
      return this.$route.name.includes('detail-kfs-customer')
    },
    goBackPath() {
      return this.isKFSCustomerForm ? `${this.config.uri}/${this.$route.params.id}` : `${this.config.uri}`
    },
  },
  watch: {
    imageField() {
      if (typeof this.vars.photo !== 'string') {
        this.vars.rulesImage = 'required|image'
      }
    },
  },
  created() {
    this.getById()
    this.getOptions()
  },
  methods: {
    setTagByKFS() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.kfs}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.since = data.since
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    setTagInfo(data) {
      this.models.since = data.name
    },
    debounceSearch(value) {
      if (value !== '') {
        this.models.since = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag()
          this.masters.regencies = [...response]
        }, 500)
      }
    },
    async handleSearchTag() {
      const res = await this.$axios.get(`${api.list_regency}?search=${this.models.since}`)
      return res.data.data.rows
    },
    getById() {
      if (this.$route.params.id || this.isKFSCustomerForm) {
        const id = this.isKFSCustomerForm ? this.$route.params.idCustomer : this.$route.params.id
        if (id) {
          this.$axios.get(`${api.customers}/${id}/profile`)
            .then(res => {
              this.vars.rulesImage = 'required'

              const { data } = res.data
              this.models.kfsId = data.kfsId
              this.models.name = data.profile.fullName
              this.models.gender = data.profile.gender
              this.models.bod = data.profile.bod
              this.models.phone = data.profile.phone
              this.models.email = data.email
              this.models.address = data.profile.address
              this.models.note = data.profile.note
              this.models.since = data.profile.since

              this.vars.photo = data.profile.photo
              this.vars.memberId = data.uniqueId
              this.vars.status = data.status
            }).catch(() => this.$router.push({
              name: 'page404',
            }))
        } else if (this.isKFSCustomerForm) {
          this.models.kfsId = this.$route.params.id
        }

        // set default tags in customer if customer has kfs
        this.$nextTick(() => {
          if (this.$route.name.includes('detail-kfs-customer') && !this.models.since && this.models.since === '') {
            this.setTagByKFS()
          }
        })
      }
    },
    AddPhoto(event) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      if (event.target.files[0].size < fileSizeLimit) {
        const photo = event.target.files[0]
        this.vars.photo = photo
        // eslint-disable-next-line no-param-reassign
        event.target.value = null
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            text: 'Max file size is 2 MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    getOptions() {
      Promise.all([
        this.$axios.get(api.list_kfs),
        this.$axios.get(api.list_regency),
      ]).then(res => {
        this.masters.kfs = [...this.masters.kfs, ...res[0].data.data]
        this.masters.regencies = [...this.masters.regencies, ...res[1].data.data.rows]
      })
    },
    updateStatus() {
      this.$axios.put(`${api.customers}/${this.$route.params.id}/status`, { status: !this.statusCustomer })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success ${this.statusCustomer ? 'Deactivated' : 'Activated'} customer`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.vars.status = !this.statusCustomer
        })
    },
    save() {
      this.$refs.formCustomers.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          // get file image url
          this.models.photo = typeof this.vars.photo === 'string' ? this.vars.photo : await uploadFile(this.vars.photo)

          if (this.isKFSCustomerForm) {
            if (!this.$route.params.idCustomer) {
              request = this.$axios.post(api.customers, this.models)
            } else {
              message = 'update'
              request = this.$axios.put(`${api.customers}/${this.$route.params.idCustomer}`, this.models)
            }
          } else if (!this.$route.params.id) {
            request = this.$axios.post(api.customers, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.customers}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} customer`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              if (this.isKFSCustomerForm) {
                this.$router.push({ name: 'detail-kfs', params: { id: this.$route.params.id, defaultActive: 'customer' } })
              } else {
                this.$router.push(this.config.uri)
              }
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
