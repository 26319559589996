var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formCustomers"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('div',{staticClass:"animated fadeIn"},[_c('b-card',{staticClass:"px-md-2"},[_c('ResponseAlert',{ref:"response"}),_c('h3',[_vm._v(_vm._s(_vm.$route.meta.breadcrumb[1].text))]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"sm":"3"}},[_c('div',{staticClass:"image-input"},[_c('validation-provider',{attrs:{"name":"Photo","rules":_vm.vars.rulesImage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-img',{staticClass:"mb-2",attrs:{"src":_vm.getUrlOfFile(_vm.vars.photo)}}),(!_vm.isDetailPage)?_c('label',{attrs:{"for":"file-input"}},[_c('input',{attrs:{"id":"file-input","type":"file","accept":"image/png, image/gif, image/jpeg, image/jpg","hidden":""},on:{"change":_vm.AddPhoto}}),_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Upload Photo ")],1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vars.photo),expression:"vars.photo"}],attrs:{"type":"hidden","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.vars.photo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vars, "photo", $event.target.value)}}}),_c('small',{staticClass:"text-danger text-center d-block m-auto"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{attrs:{"md":"9"}},[_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"h5",attrs:{"for":"name"}},[_vm._v("Member ID")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"Name","type":"text","disabled":""},model:{value:(_vm.vars.memberId),callback:function ($$v) {_vm.$set(_vm.vars, "memberId", $$v)},expression:"vars.memberId"}})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"h5",attrs:{"for":"name"}},[_vm._v("Nama")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isDetailPage,"type":"text","state":errors.length > 0 ? false:null},model:{value:(_vm.models.name),callback:function ($$v) {_vm.$set(_vm.models, "name", $$v)},expression:"models.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"h5",attrs:{"for":"email"}},[_vm._v("Email")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":_vm.isDetailPage,"type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.models.email),callback:function ($$v) {_vm.$set(_vm.models, "email", $$v)},expression:"models.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"h5",attrs:{"for":"phone_number"}},[_vm._v("No Telepon")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('validation-provider',{attrs:{"name":"No. telepon","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","disabled":_vm.isDetailPage,"type":"tel","state":errors.length > 0 ? false:null},model:{value:(_vm.models.phone),callback:function ($$v) {_vm.$set(_vm.models, "phone", $$v)},expression:"models.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"9"}},[_c('b-row',{staticClass:"my-1",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"bod"}},[_vm._v("Tanggal Lahir")])]),_c('b-col',{attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":"Tanggal lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"bod","show-decade-nav":"","max":_vm.vars.maxDate,"disabled":_vm.isDetailPage,"state":errors.length > 0 ? false:null},model:{value:(_vm.models.bod),callback:function ($$v) {_vm.$set(_vm.models, "bod", $$v)},expression:"models.bod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"name"}},[_vm._v("Jenis Kelamin")])]),_c('b-col',{attrs:{"sm":"8"}},[(_vm.isDetailPage)?_c('b-form-input',{attrs:{"value":_vm.models.gender.toUpperCase(),"disabled":""}}):_c('div',{staticClass:"col-sm-6 d-flex justify-content-between"},[_c('label',{staticClass:"radio-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.models.gender),expression:"models.gender"}],attrs:{"type":"radio","value":"perempuan","name":"gender"},domProps:{"checked":_vm._q(_vm.models.gender,"perempuan")},on:{"change":function($event){return _vm.$set(_vm.models, "gender", "perempuan")}}}),_c('span',[_vm._v("Perempuan")])]),_c('label',{staticClass:"radio-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.models.gender),expression:"models.gender"}],attrs:{"type":"radio","value":"laki","name":"gender"},domProps:{"checked":_vm._q(_vm.models.gender,"laki")},on:{"change":function($event){return _vm.$set(_vm.models, "gender", "laki")}}}),_c('span',[_vm._v("Laki-Laki")])])])],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"address"}},[_vm._v("Alamat")])]),_c('b-col',{attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","disabled":_vm.isDetailPage,"state":errors.length > 0 ? false:null,"rows":"3"},model:{value:(_vm.models.address),callback:function ($$v) {_vm.$set(_vm.models, "address", $$v)},expression:"models.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"kfs"}},[_vm._v("KFS")])]),_c('b-col',{attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":"KFS","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"w-100",attrs:{"id":"kfs","disabled":_vm.isKFSCustomerForm,"state":errors.length > 0 ? false:null,"options":_vm.masters.kfs,"value-field":"id","text-field":"name"},model:{value:(_vm.models.kfsId),callback:function ($$v) {_vm.$set(_vm.models, "kfsId", $$v)},expression:"models.kfsId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1",attrs:{"align-v":"baseline"}},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"tag"}},[_vm._v("Tags")])]),_c('b-col',{attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":"Tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"tag","label":"name","placeholder":"- Cari Tag -","state":errors.length > 0 ? false:null,"options":_vm.masters.regencies,"reduce":function (option) { return option; },"disabled":_vm.isDetailPage},on:{"search":_vm.debounceSearch,"input":_vm.setTagInfo},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Tidak ditemukan hasil pencarian ")]}}],null,true),model:{value:(_vm.models.since),callback:function ($$v) {_vm.$set(_vm.models, "since", $$v)},expression:"models.since"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"h5",attrs:{"for":"note"}},[_vm._v("Catatan")])]),_c('b-col',{attrs:{"sm":"8"}},[_c('validation-provider',{attrs:{"name":"Catatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note","disabled":_vm.isDetailPage,"state":errors.length > 0 ? false:null,"rows":"3"},model:{value:(_vm.models.note),callback:function ($$v) {_vm.$set(_vm.models, "note", $$v)},expression:"models.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-center justify-content-md-end"},[(_vm.isEditCustomerPage)?_c('b-button',{staticClass:"btn-min-width rounded",attrs:{"type":"button","variant":("outline-" + (_vm.statusCustomer ? 'danger' : 'success'))},on:{"click":_vm.updateStatus}},[_vm._v(" "+_vm._s(_vm.statusCustomer ? 'Non-Aktif' : 'Aktif')+" ")]):_vm._e(),_c('b-button',{staticClass:"btn-min-width rounded mx-md-1 my-1 my-md-0",attrs:{"type":"button","variant":"light"},on:{"click":function($event){return _vm.$router.push({path: _vm.goBackPath})}}},[_vm._v(" Kembali ")]),(!_vm.isDetailPage)?_c('b-button',{staticClass:"btn-min-width rounded",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }